import styles from './styles.module.scss';

const AspectRatioBox = ({aspectRatio, className, children, ...props}) => {
  return (
    <div className={styles.box} style={{'--aspect-ratio': aspectRatio || '3/2'}}>
      <div className={className} {...props}>{children}</div>
    </div>
  );
};

export default AspectRatioBox;
