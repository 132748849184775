import {useEffect, useState} from 'react';
import {useRouter as useNextRouter} from 'next/router';
import useSwr, {SWRConfig} from 'swr';
import {
  SplitLayout,
  SplitCol,
  Epic,
  ModalRoot,
  Alert,
} from "@vkontakte/vkui";

import axiosInstance, { fetcher } from '../api/axios';
// import prisma from '../../lib/prisma';
// import getUserCity from '../api/getUserCity'
// import getAnniversaryCities from '../api/getAnniversaryCities'

// import { parseLaunchParams } from '../helpers/parseLaunchParams'

import withStore from '../store/withStore';
import {useEventsFilter} from '../store/eventsFilterContext';

import JazzPanel from '../panels/Jazz';
import ArticlePanel from '../panels/article';
import PosterPanel from '../panels/poster';
import ClipsPanel from '../panels/clips';
import PlaylistsPanel from '../panels/Playlists';
import VideosPanel from '../panels/Videos';

import MainEventCitySelectModal from '../modals/mainEventCitySelect';
import EditEventModal from '../modals/EditEvent';
import EditClipModal from '../modals/EditMedia';
import FilterEventsModal from '../modals/FilterEvents';

import Tabbar from '../blocks/Tabbar';

import {useRouter} from '../store/routerContext';
import {useUser} from '../store/userContext';
import {useModal} from '../store/modalContext';

import {modals/*, mainEventCities*/} from '../constants';

function Modals({ anniversaryCities }) {
  const {activeModal, closeModal, setPopout, closePopout} = useModal();

  const dangerouslyCloseModal = () => {
    setPopout(
      <Alert
        actions={[
          {
            title: 'Вернуться',
            autoclose: true,
            mode: 'default'
          },
          {
            title: 'Ок',
            autoclose: true,
            mode: 'destructive',
            action: closeModal
          }
        ]}
        actionsLayout="horizontal"
        onClose={closePopout}
        header='Внимание'
        text='Все несохраненные данные будут удалены'
      />
    )
  }

  const handleClose = () => {
    activeModal === modals.newEvent ? dangerouslyCloseModal() : closeModal()
  }

  return (
    <ModalRoot onClose={handleClose} activeModal={activeModal}>
      <MainEventCitySelectModal id={modals.mainEvent} anniversaryCities={anniversaryCities}/>
      <EditEventModal id={modals.newEvent}/>
      <FilterEventsModal id={modals.eventsFilter} />
      <EditClipModal id={modals.mediaModal} />
    </ModalRoot>
  )
}

const PANELS = [
  'jazz',
  'poster',
  'clips',
  'playlists',
  'videos',
]

function App({isDesktop, /*isAdmin, userCity, fallback = {}, jazzPageSettings, anniversaryCities*/}) {
  const router = useNextRouter()
  const {activePanel, setActivePanel} = useRouter()
  const [,setFilters] = useEventsFilter()
  const {popout} = useModal()
  const [, setUser] = useUser()

  const [loading, setLoading] = useState(true)
  const [props, setProps] = useState({})

  const {data: article, mutate: mutateArticle} = useSwr('/api/getArticle', fetcher)

  useEffect(() => {
    axiosInstance.get(`/api/loadConfig`)
      .then(({data})=>data)
      .then((props) => {
        const {isAdmin, userCity, /*fallback = {},*/ jazzPageSettings, anniversaryCities} = props

        if (isAdmin) {
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${window.location.search.slice(1)}&admin=true`
        }
        if (isAdmin || userCity) {
          setUser(prev => ({ ...prev, isAdmin, city: userCity || prev.city }))
        }

        setProps({ jazzPageSettings, anniversaryCities })
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => { setLoading(false) })

    document.body.setAttribute('scheme', 'jazz')
    if (isDesktop) document.body.classList.add('desktop')

    //TODO: разобраться с навигацией
    if (location.hash === '#myNotifications') {
      setFilters((f) => ({...f, subscriptions: 'true'}))
      setActivePanel('poster')
      router.replace('#poster')
    } else {
      router.replace('#jazz')
    }

    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        const prevPanel = as.split('#')[1]
        if (PANELS.indexOf(prevPanel) > -1) {
          setActivePanel(prevPanel)
        }
        if (prevPanel.indexOf('poster|')>-1) {
          setActivePanel('poster')
        }
        return false
      }
      return true
    })
  }, [])

  return loading ? null : (
      <SWRConfig value={{ /*fallback,*/ fetcher }}>
        <SplitLayout modal={<Modals anniversaryCities={props?.anniversaryCities} />} popout={popout}>
          <SplitCol>
            <Epic
              activeStory={activePanel}
              tabbar={<Tabbar />}
            >
              <JazzPanel id='jazz' article={article} settings={props?.jazzPageSettings} />
              <ArticlePanel id='article' article={article} mutateArticle={mutateArticle} />
              <PosterPanel id='poster' />
              <ClipsPanel id='clips'/>
              <PlaylistsPanel id='playlists'/>
              <VideosPanel id='videos'/>
            </Epic>
          </SplitCol>
        </SplitLayout>
      </SWRConfig>
  )
}

// export async function getServerSideProps(ctx) {
//   const {vk_user_id} = parseLaunchParams(ctx.req.url.split('?')[1] || '')
//   let city = {};
//   if (vk_user_id) {
//     [city] = await getUserCity(Number(vk_user_id)).catch(err => { console.error(err); return [] })
//   }
//
//   // console.log('===============')
//   // console.log(ctx.req.url)
//   // console.log(vk_user_id, city)
//   // console.log(`https://api.vk.com/method/groups.getMembers?group_id=${process.env.VK_GROUP_ID}&filter=managers&access_token=${process.env.VK_GROUP_TOKEN}&v=${process.env.VK_API_VERSION}`)
//   // console.log('===============')
//
//   let admins = [70083964, 47816399]
//   const adminsRes = await fetch(`https://api.vk.com/method/groups.getMembers?group_id=${process.env.VK_GROUP_ID}&filter=managers&access_token=${process.env.VK_GROUP_TOKEN}&v=${process.env.VK_API_VERSION}`).then(res => res.json())
//   if (adminsRes.response && adminsRes.response.items) {
//     admins = [...admins, ...adminsRes.response.items.map(({id}) => id)]
//   }
//
//   const anniversaryCities = await getAnniversaryCities()
//   const jazzPageSettings = await prisma.pageSettings.findUnique({
//     where: {
//       page: 'jazz'
//     }
//   })
//   // const _events = await prisma.event.findMany({
//   //   where: {
//   //     isPublished: true
//   //   }
//   // })
//   // const events = _events.map(({startDate, ...event}) => ({startDate: +startDate, ...event}))
//
//   // const _media = await prisma.event.findMany({
//   //   where: {
//   //     isPublished: true
//   //   }
//   // })
//   // const events = _events.map(({startDate, ...event}) => ({startDate: +startDate, ...event}))
//
//   const cityToMainCities = mainEventCities.find(({id}) => id === Number(city?.city?.id))
//   return {
//     props: {
//       anniversaryCities,
//       isAdmin: admins.indexOf(Number(vk_user_id)) > -1,
//       userCity: city.is_closed || !city.city ? '1|Москва' : cityToMainCities ? `${cityToMainCities?.id}|${cityToMainCities?.title}` : '1|Москва',//`${city.city.id}|${city.city.title}`,
//       jazzPageSettings: jazzPageSettings?.settings,
//       fallback: {
//       //   '/api/getEvents': events
//       }
//     }
//   }
// }

export default withStore(App)
