import { useRouter as useNextRouter } from 'next/router';
import { Title, Text, Button } from '@vkontakte/vkui';

import {useRouter} from "../../store/routerContext";

import styles from './styles.module.scss';

const JazzArticle = ({ article: { title, text } }) => {
  const router = useNextRouter()
  const { setActivePanel } = useRouter()

  if (!text) return null

  return (
    <>
      <Title level={1} className={styles.title}>{title}</Title>
      <Text weight='regular' className={styles.text} dangerouslySetInnerHTML={{ __html: text.slice(0, 500)+'...' }} />
      {/*<Text weight='regular' className={styles.text}>{text.replace('\n', '<br/><br/>')}</Text>*/}
      <Button
        stretched
        mode='outline'
        size='l'
        onClick={() => { router.push('#article'); setActivePanel('article') }}
      >
        Читать дальше
      </Button>
    </>
  );
};

export default JazzArticle;
