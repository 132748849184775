import { Text } from '@vkontakte/vkui';
import cn from 'classnames';

import IconPin from './pin.svg';

import styles from './styles.module.scss';

const Address = ({ address, accent }) => {
  return (
    <div className={cn(styles.wrapper, accent && styles.accent)}>
      <IconPin className={styles.icon} />
      <Text weight='regular'><span dangerouslySetInnerHTML={{ __html:address }} /></Text>
    </div>
  );
};

export default Address;
