import {useState, forwardRef} from "react";
import {CustomSelect, IconButton} from "@vkontakte/vkui";
import {Icon24Cancel} from "@vkontakte/icons";

const defaultValue = '1|Россия'
const CIS = [
  {value: '1|Россия', label: 'Россия'},
  {value: '6|Армения', label: 'Армения'},
  {value: '5|Азербайджан', label: 'Азербайджан'},
  {value: '3|Беларусь', label: 'Беларусь'},
  {value: '4|Казахстан', label: 'Казахстан'},
  {value: '11|Кыргызстан', label: 'Кыргызстан'},
  {value: '15|Молдова', label: 'Молдова'},
  {value: '16|Таджикистан', label: 'Таджикистан'},
  {value: '17|Туркменистан', label: 'Туркменистан'},
  {value: '2|Украина', label: 'Украина'},
  {value: '18|Узбекистан', label: 'Узбекистан'},
]


const CountrySelect = ({ selected = defaultValue, onChange, ...props }, ref) => {
  const [countries, setCountries] = useState(CIS)

  const handleSearch = (e) => {
    const q = e.target.value
    if (q === '') {
      setCountries(CIS)
      return
    }
    const countries = CIS.filter(({ label }) => label.toLowerCase().indexOf(q.toLowerCase()) > -1)
    setCountries(countries)
  }

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  return (
    <CustomSelect
      value={selected}
      ref={ref}
      searchable
      options={countries}
      onInputChange={handleSearch}
      filterFn={false}
      popupDirection='top'
      onChange={handleChange}
      icon={
        Boolean(selected) && selected !== defaultValue ?
          <IconButton
            mode='tertiary'
            appearance='overlay'
            onClick={(e) => { e.stopPropagation(); onChange(defaultValue); }}
          >
            <Icon24Cancel />
          </IconButton>
          : undefined
      }
      {...props}
    />
  )
}

export default forwardRef(CountrySelect)
