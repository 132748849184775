import {useState, useEffect} from 'react';
import {useSWRConfig} from 'swr';

import {
  Div,
  Spinner,
  ButtonGroup,
  Button,
  ModalPage,
  ModalPageHeader,
  Separator,
  Alert,
  Title,
  FormLayout,
  FormItem,
  Input,
  Textarea,
  DatePicker,
  Checkbox,
} from '@vkontakte/vkui';
import {Icon24Dismiss} from "@vkontakte/icons";

import axios from '../../api/axios';

import {useRouter} from "../../store/routerContext";
import {useUser} from '../../store/userContext';
import {useModal} from '../../store/modalContext';
import {useEvent, emptyEvent} from '../../store/eventContext';

import {modals} from '../../constants';

import CountrySelect from '../../components/CountrySelect';
import CitySelect from '../../components/CitySelect';
import ImageInput from '../../components/ImageInput';

import styles from './styles.module.scss';

const normalizeValue = (key, value) => {
  switch (key) {
    // case 'creatorId':
    //   return Number(value)
    default:
      return value
  }
}

// const emptyEvent = (user) => ({
//   cover: null,
//   title: null,
//   description: null,
//   city: null,
//   startDate: add(new Date().setHours(0,0,0,0), {weeks: 1}),
//   address: null,
//   buyLink: null,
//
//   isSpecial: false,
//   creatorId: Number(user.id),
// })
const normalizeEventData = ({startDate, endDate, buyUrl, SPECIAL, ANNIVERSARY, ...eventData}) => ({
  startDate: new Date(startDate),
  endDate: new Date(endDate),
  ...eventData,
  // tags: [SPECIAL ? 'SPECIAL' : false, ANNIVERSARY ? 'ANNIVERSARY' : false].filter(Boolean),
})

const linkRegex = /[-a-zA-Z0-9:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9():%_+.~#?&/=]*)/gi

const validate = (eventData) => {
  const {
    startDate,
    endDate,
    buyLink
  } = eventData
  const errors = {}

  Object.keys(eventData).forEach(k => {
    if (['city', 'buyLink', 'address'].indexOf(k) === -1 && typeof eventData[k] == 'string' && eventData[k].length === 0) errors[k] = true
  })

  if (typeof buyLink == 'string' && buyLink.match(linkRegex) == null) {
    errors.buyLink = true
  }

  if (endDate > 0 && +endDate < +startDate) {
    errors.endDate = true
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  }
}

const EditEvent = () => {
  const {mutate} = useSWRConfig()

  const {mutate: str} = useRouter()
  const [user] = useUser()
  const {closeModal, setPopout, closePopout} = useModal()
  const [data, setData] = useEvent()
  const [errors, setErrors] = useState({})

  // const [isTouched, setIsTouched] = useState(false)

  // const [isCoverLoading, setIsCoverLoading] = useState(false)

  const handleChange = (key, value) => {
    // if (!isTouched) setIsTouched(true)
    setData(prev => ({...prev, [key]: normalizeValue(key, value)}))
    if (errors[key]) setErrors(prev => ({...prev, [key]: false}))
  }

  const handleFileUpload = (image) => handleChange('cover', image)

  const handleChangeInput = (e) => handleChange(e.target.name, e.target.value)
  const handleChangeCountry = (country) => {
    handleChange('country', country)
    handleChange('city', '')
  }
  const handleChangeCity = (city) => handleChange('city', city)
  const handleChangeStartDate = (value) => handleChange('startDate', new Date(value.year, value.month - 1, value.day))
  const handleChangeEndDate = (value) => handleChange('endDate', new Date(value.year, value.month - 1, value.day))
  const handleChangeTagCheckbox = (e) => {
    let _tags = [...data.tags]
    if (e.target.checked) {
      _tags.push(e.target.name)
    } else {
      _tags.splice(_tags.indexOf(e.target.name), 1)
    }
    handleChange('tags', _tags)
  }

  const scrollToInput = (inputId) => {
    const input = document?.querySelector(`#${inputId}_input`)
    if (input) {
      input.scrollIntoView({behavior: 'smooth'})
    }
  }

  const handleClickSave = () => {
    const {id, ...normalizedData} = normalizeEventData(data)

    const {isValid, errors} = validate(normalizedData)

    if (!isValid) {
      setErrors(errors)
      scrollToInput(Object.keys(errors)[0])
      return
    }

    if (data.id > 0) {
      axios.put(`/api/event/${id}`, normalizedData)
        .then(() => {
          console.log(normalizedData)
          closeModal()
        })
        .catch((error) => {
          // console.log(err)
          if (error.response) {
            switch (error.response.status) {
              case 429: {
                setPopout(
                  <Alert
                    actions={[
                      {
                        title: "Хорошо",
                        autoclose: true,
                        mode: "default",
                      }
                    ]}
                    actionsLayout="horizontal"
                    // onClose={() => {
                    //   closePopout()
                    // }}
                    header='Ошибка'
                    text='Слишком много запросов, попробуйте позже'
                  />
                )
                return
              }
              case 400: {
                setErrors(error.response.data)
                scrollToInput(Object.keys(error.response.data)[0])
                return
              }
              case 413: {
                setErrors({cover: true})
                // scrollToInput('cover')
                setPopout(
                  <Alert
                    actions={[
                      {
                        title: "Поменяю",
                        autoclose: true,
                        mode: "default",
                      }
                    ]}
                    actionsLayout="horizontal"
                    onClose={() => {
                      closePopout()
                    }}
                    header='Ошибка'
                    text='Слишком большой размер изображения'
                  />
                )
                return
              }
              default:
                return;
            }
          }
        })
    } else {
      axios.post('/api/event', normalizedData)
        .then(() => {
          setPopout(
            <Alert
              actions={[
                {
                  title: "Хорошо",
                  autoclose: true,
                  mode: "default",
                }
              ]}
              actionsLayout="horizontal"
              onClose={() => {
                closePopout()
                closeModal()
              }}
              header='Подтверждение'
              text='Ваше мероприятие будет опубликовано после прохождения модерации.'
            />
          )
        })
        .catch((error) => {
          // console.log(err)
          if (error.response) {
            switch (error.response.status) {
              case 429: {
                setPopout(
                  <Alert
                    actions={[
                      {
                        title: "Хорошо",
                        autoclose: true,
                        mode: "default",
                      }
                    ]}
                    actionsLayout="horizontal"
                    // onClose={() => {
                    //   closePopout()
                    // }}
                    header='Ошибка'
                    text='Слишком много запросов, попробуйте позже'
                  />
                )
                return
              }
              case 400: {
                setErrors(error.response.data)
                scrollToInput(Object.keys(error.response.data)[0])
                return
              }
              case 413: {
                setErrors({cover: true})
                setPopout(
                  <Alert
                    actions={[
                      {
                        title: "Поменяю",
                        autoclose: true,
                        mode: "default",
                      }
                    ]}
                    actionsLayout="horizontal"
                    onClose={() => {
                      closePopout()
                    }}
                    header='Ошибка'
                    text='Слишком большой размер изображения'
                  />
                )
                return
              }
              default:
                return;
            }
          }
        })
    }
  }

  const handleClickCancel = () => {
    const isEditing = data?.id > 0
    setPopout(
      <Alert
        actions={[
          {
            title: "Вернуться",
            autoclose: true,
            mode: "default",
          },
          {
            title: "Ок",
            // autoclose: true,
            mode: "destructive",
            action: () => {
              closeModal()
              setTimeout(closePopout, 150)
            }
          }
        ]}
        actionsLayout="horizontal"
        onClose={closePopout}
        header={isEditing ? 'Внимание' : 'Подтверждение'}
        text={isEditing ? 'Все несохраненные данные будут удалены' : 'Вы действительно хотите удалить введенные данные?'}
      />
    )
  }

  useEffect(() => {
    // if (data?.id === -1) setData(prev => ({...prev, creatorId: user.id}))
    // setData(event || emptyEvent(user))

    return () => {
      // mutate('/api/getEvents?moderation=false')
      mutate(str)
      setData(emptyEvent)
    }
  }, [])

  return !data ? <Spinner/> : (
    <ModalPage
      id={modals.newEvent}
      className={styles.modalPage}
      header={
        <>
          <ModalPageHeader
            after={
              <Button mode='tertiary' appearance='overlay' onClick={handleClickCancel}>
                <Icon24Dismiss/>
              </Button>
            }
          >
            <Title level={3}>{data.id > 0 ? 'Редактирование события' : 'Новое событие'}</Title>
          </ModalPageHeader>
          <Separator style={{color: 'var(--grey)'}}/>
        </>
      }
    >
      <FormLayout>
        <FormItem id='cover_input' top='Изображение' status={errors.cover ? "error" : ""}
                  bottom={errors.cover ? 'Необходимо загрузить обложку' : null}>
          <ImageInput
            className={styles.fileInput}
            defaultImg={data.cover}
            onChange={handleFileUpload}
          />
        </FormItem>
        <FormItem id='title_input' top='Название' status={errors.title ? "error" : ""}>
          <Input
            name='title'
            placeholder='Укажите название'
            value={data.title}
            onChange={handleChangeInput}
            maxLength="256"
          />
        </FormItem>
        <FormItem id='description_input' top='Описание' status={errors.description ? "error" : ""}>
          <Textarea
            name='description'
            placeholder='Укажите описание'
            maxLength="1024"
            value={data.description}
            onChange={handleChangeInput}
          />
        </FormItem>
        <FormItem top='Страна'>
          <CountrySelect
            selected={data.country}
            onChange={handleChangeCountry}
          />
        </FormItem>
        <FormItem top='Город'>
          <CitySelect
            selected={data.city}
            onChange={handleChangeCity}
            clearable
            countryId={Number(data.country?.split('|')[0])}
          />
        </FormItem>
        <FormItem top='Дата начала'>
          <DatePicker
            min={{day: 1, month: 1, year: 2022}}
            max={{day: 1, month: 1, year: 2222}}
            defaultValue={
              data?.startDate > 0 ?
                {
                  day: new Date(data.startDate).getDate(),
                  month: new Date(data.startDate).getMonth() + 1,
                  year: new Date(data.startDate).getFullYear()
                }
                :
                {
                  year: new Date().getFullYear()
                }
            }
            popupDirection='top'
            onDateChange={handleChangeStartDate}
            dayPlaceholder="ДД"
            monthPlaceholder="ММММ"
            yearPlaceholder="ГГГГ"
            placeholder='Дата начала'
          />
        </FormItem>
        <FormItem
          id='endDate_input'
          top='Дата окончания'
          status={errors.endDate ? "error" : ""}
          bottom={errors.endDate ? 'Неверно указана дата' : null}
        >
          <DatePicker
            name='endDate'
            min={{day: 1, month: 1, year: 2022}}
            max={{day: 1, month: 1, year: 2222}}
            defaultValue={data?.endDate > 0 ? {
              day: new Date(data.endDate).getDate(),
              month: new Date(data.endDate).getMonth() + 1,
              year: new Date(data.endDate).getFullYear()
            } : {
              year: new Date(data.startDate).getFullYear()
            }}
            popupDirection='top'
            onDateChange={handleChangeEndDate}
            dayPlaceholder="ДД"
            monthPlaceholder="ММММ"
            yearPlaceholder="ГГГГ"
            placeholder='Дата окончания'
          />
        </FormItem>
        <FormItem id='address_input' top='Адрес' status={errors.address ? "error" : ""}>
          <Input
            name='address'
            placeholder='Укажите адрес'
            value={data.address}
            onChange={handleChangeInput}
            maxLength="256"
          />
        </FormItem>
        <FormItem
          id='buyLink_input'
          top='Ссылка'
          status={errors.buyLink ? "error" : ""}
          bottom={errors.buyLink ? 'Неверно указана ссылка' : null}
        >
          <Input
            name='buyLink'
            placeholder='Укажите ссылку'
            value={data.buyLink}
            onChange={handleChangeInput}
            maxLength="256"
          />
        </FormItem>
        {
          user.isAdmin ?
            <>
              <Checkbox name='SPECIAL' checked={data?.SPECIAL || data?.tags?.indexOf('SPECIAL') > -1}
                        onChange={handleChangeTagCheckbox}>Важное</Checkbox>
              <Checkbox
                name='ANNIVERSARY'
                checked={data?.ANNIVERSARY || data?.tags?.indexOf('ANNIVERSARY') > -1}
                onChange={handleChangeTagCheckbox}
              >
                100 лет джазу
              </Checkbox>
            </>
            : null
        }
      </FormLayout>
      <Div>
        <ButtonGroup stretched>
          <Button size='l' stretched onClick={handleClickSave}>Сохранить</Button>
          <Button mode='outline' size='l' stretched onClick={handleClickCancel}>Отменить</Button>
        </ButtonGroup>
      </Div>
    </ModalPage>
  );
};

export default EditEvent;
