import {useState, useEffect} from 'react';

import styles from './styles.module.scss';

const Iframe = props => {
  const [isShowed, setIsShowed] = useState(false)

  useEffect(() => {
    setIsShowed(true)
  }, [])

  return (
    <>
      {isShowed ? <iframe {...props} className={styles.frame}/> : null}
    </>
  );
};

export default Iframe;
