import { useEffect, useCallback } from 'react';
import Script from 'next/script';
import useSwr from 'swr';
import {
  Alert,
  Button,
  Div,
  Panel,
  PanelSpinner,
  PanelHeader,
  Title,
} from '@vkontakte/vkui';
import { Icon28AddOutline } from '@vkontakte/icons';

import axios from '../../api/axios';
import { useGlobal } from '../../store/globalContext';
import { useModal } from '../../store/modalContext';
import { useUser } from '../../store/userContext';
import { useMedia, MEDIA_TYPE } from '../../store/mediaContext';

import { modals } from '../../constants';

import EditButtonsGroup from '../../components/EditButtonsGroup';

import styles from './styles.module.scss';

const FIXED_PLAYLISTS = []; //[{link:'vk_playlist_-147845620_2540, -147845620, 2540,58c0c73d4870b79b88'}]

const Playlists = () => {
  const { isOdr } = useGlobal();
  const [{ isAdmin }] = useUser();
  const { setMedia, setMediaType } = useMedia();
  const { setModal, setPopout, closePopout } = useModal();

  const { data, mutate } = useSwr(`/api/getMedia?type=${MEDIA_TYPE.playlist}`);

  const handleClickNew = () => {
    setMediaType(MEDIA_TYPE.playlist);
    setModal(modals.mediaModal);
  };

  const handleClickEdit = (media) => {
    setMedia(media);
    setModal(modals.mediaModal);
  };

  const handleClickDelete = (playlist) => {
    setPopout(
      <Alert
        actions={[
          {
            title: 'Оставить',
            mode: 'default',
            action: closePopout,
          },
          {
            title: 'Ок',
            autoclose: true,
            mode: 'destructive',
            action: () => {
              axios
                .delete(`/api/media/${playlist?.id}`)
                .finally(() => mutate());
            },
          },
        ]}
        actionsLayout="horizontal"
        onClose={closePopout}
        header="Подтверждение"
        text="Вы действительно хотите удалить плейлист?"
      />
    );
  };

  const loadPlaylists = useCallback(() => {
    [...FIXED_PLAYLISTS, ...(data ?? [])].forEach((p) => {
      typeof VK !== 'undefined' && VK?.Widgets?.Playlist(...p?.link.split(','));
    });
  }, [data]);

  const handleOpenAPILoad = () => {
    loadPlaylists();
  };

  useEffect(() => {
    loadPlaylists();
  }, [data]);

  useEffect(() => {
    typeof VK !== 'undefined' && VK?.Widgets && loadPlaylists();
  }, []);

  return (
    <Panel>
      <Script
        src="https://vk.com/js/api/openapi.js?169"
        onLoad={handleOpenAPILoad}
      />
      {isOdr ? <br /> : null}
      <PanelHeader fixed={false} separator={false} transparent>
        <Title level={3}>Плейлисты</Title>
      </PanelHeader>
      {isOdr ? <br /> : null}

      {isAdmin ? (
        <Div>
          <Button
            before={<Icon28AddOutline />}
            size="l"
            mode="primary"
            stretched
            onClick={handleClickNew}
          >
            Добавить плейлист
          </Button>
        </Div>
      ) : null}

      {!data ? <PanelSpinner /> : null}
      <Div>
        {[...FIXED_PLAYLISTS, ...(data ?? [])].map((p, idx) => (
          <>
            <div
              key={idx}
              id={p?.link?.split(',')?.[0]}
              className={styles.playlist}
              style={{ borderRadius: 10 }}
            />
            {isAdmin && p?.id ? (
              <div style={{ marginBottom: 18 }}>
                <EditButtonsGroup
                  onClickEdit={() => handleClickEdit(p)}
                  onClickDelete={() => handleClickDelete(p)}
                />
              </div>
            ) : null}
          </>
        ))}
      </Div>
    </Panel>
  );
};

export default Playlists;
