import { createContext, useContext, useState } from 'react';
import add from "date-fns/add";

const EventContext = createContext()

export const emptyEvent = {
  id: -1,
  cover: '',
  title: '',
  description: '',
  country: '1|Россия',
  city: '',
  startDate: +add(new Date().setHours(0,0,0,0), {weeks: 1}),
  endDate: 0,
  address: '',
  buyLink: '',

  // creatorId: 0,
}

function EventProvider({children}) {
  const [event, setEvent] = useState(emptyEvent)
  const value = [event, setEvent]
  return <EventContext.Provider value={value}>{children}</EventContext.Provider>
}

function useEvent() {
  const context = useContext(EventContext)
  if (context === undefined) {
    throw new Error('useEvent must be used within a EventProvider')
  }
  return context
}

export {EventProvider, useEvent}
