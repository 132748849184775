import {useState, useEffect} from 'react';
import {useRouter as useNextRouter} from 'next/router';
import useSWR from 'swr'
import {
  View,
  Panel,
  PanelHeader,
  PanelHeaderContent,
  PanelHeaderContext,
  List,
  Cell,
  Card,
  Div,
  Title,
  Button,
  CardGrid,
  Alert, PanelSpinner, ButtonGroup, Link
} from "@vkontakte/vkui";
import {
  Icon24Filter,
  Icon24Similar,
  Icon28AddOutline,
  Icon28ChecksOutline,
  Icon16Dropdown,
  Icon24Done,
  Icon24PenOutline,
  Icon24DeleteOutline,
  Icon28ShareExternal,
  Icon24User
} from '@vkontakte/icons';

import axios from "../api/axios";

import {useGlobal} from "../store/globalContext";
import {useEvent} from "../store/eventContext";
import {useEventsFilter} from "../store/eventsFilterContext";
import {useUser} from '../store/userContext';
import {useModal} from '../store/modalContext';
import {useRouter} from '../store/routerContext';

import {modals} from "../constants";

import Event from '../blocks/Event';

import EventPanel from './Event';

const PANELS = [
  'jazz',
  'poster',
  'clips',
  'playlists',
  'videos',
]

const Poster = () => {
  const router = useNextRouter()
  const {setActivePanel, setMutateStr} = useRouter()
  const {isDesktop, isOdr} = useGlobal()
  const [{isAdmin}] = useUser()
  const {setModal, setPopout, closePopout} = useModal()
  const [, setEvent] = useEvent()
  const [filters, , clearFilters] = useEventsFilter()

  const [currentPanel, setCurrentPanel] = useState('poster')
  const [activeEvent, setActiveEvent] = useState()
  const [contextOpened, setContextOpened] = useState()
  const [isModeration, setIsModeration] = useState(false)
  const params = {
    country: filters.country,
    city: filters.city,
    from: filters.from,
    to: filters.to,
    subscriptions: filters.subscriptions
  }
  const filterQuery = Object.entries(params).filter(([, value]) => Boolean(value)).map(param => param.join('=')).join('&')
  const {data: events, error, mutate} = useSWR(() => `/api/getEvents?${isModeration ? `moderation=true` : filterQuery}`)

  const handleClickFilters = () => {
    setModal(modals.eventsFilter)
  }

  const handleClickContext = () => {
    if (isAdmin) setContextOpened(!contextOpened)
  }

  const onModerationMode = () => {
    setIsModeration(true)
    handleClickContext()
  }
  const offModerationMode = () => {
    setIsModeration(false)
    handleClickContext()
  }

  const handleClickEvent = (event) => {
    router.push(`#poster|${event?.id}`)
    setActiveEvent(event)
    setCurrentPanel('event')
  }

  const handleClickBack = () => {
    // router.push(`#poster`)
    router.replace(`#poster`)
    setActiveEvent(undefined)
    setCurrentPanel('poster')
  }

  const handleClickNew = () => {
    setModal(modals.newEvent)
  }

  const handleClickEdit = (event) => {
    setEvent(event)
    setModal(modals.newEvent)
  }

  const handleClickDelete = (event) => {
    setPopout(
      <Alert
        actions={[
          {
            title: "Оставить",
            mode: "default",
            action: closePopout
          },
          {
            title: "Ок",
            autoclose: true,
            mode: "destructive",
            action: () => {
              axios.delete(`/api/event/${event?.id}`).finally(() => mutate())
            }
          },
        ]}
        actionsLayout="horizontal"
        onClose={closePopout}
        header='Подтверждение'
        text='Вы действительно хотите удалить событие?'
      />
    )
  }

  const handleClickModerate = (event) => {
    axios.put(`/api/event/${event?.id}`, {
      ...event,
      startDate: new Date(event.startDate),
      endDate: new Date(event.endDate || 0),
      status: 'PUBLISHED',
      // moderatorId: Number(userId)
    })
      .finally(mutate)
  }

  useEffect(() => {
    setMutateStr(`/api/getEvents?${isModeration ? `moderation=true` : filterQuery}`)
    const urlId = Number(router.asPath.split('poster|')[1])
    if (urlId) {
      const event = events.find(({id}) => id === urlId)
      if (event) {
        handleClickEvent(event)
      }
    }
  }, [events])

  useEffect(() => {
    setMutateStr(`/api/getEvents?${isModeration ? `moderation=true` : filterQuery}`)
    router.beforePopState(({ as}) => {
          handleClickBack()
      if (as !== router.asPath) {
        const prevPanel = as.split('#')[1]
        if (PANELS.indexOf(prevPanel) > -1) {
          setActivePanel(prevPanel)
        }
        if (prevPanel.indexOf('poster|')>-1) {
          setActivePanel('poster')
        }
        // if (prevPanel.indexOf('poster|')>-1) {
        //   const event = events.find(({id}) => id === Number(prevPanel.split('|')[1]))
        //   if (event) {
        //     handleClickEvent(event)
        //   }
        // }
        // if (prevPanel === 'poster') {
        // }
        // return true
      }
      return true
    })
  }, [])

  return (
    <View activePanel={currentPanel}>
      <Panel id='poster'>
        {isOdr?<br/>:null}
        <PanelHeader
          fixed={false}
          separator={false}
          left={
            isModeration ? null :
              <Button mode='tertiary' onClick={handleClickFilters}>
                <Icon24Filter/>
              </Button>
          }
        >
          <PanelHeaderContent
            aside={isAdmin ?
              <Icon16Dropdown
                style={{
                  transform: `rotate(${
                    contextOpened ? "180deg" : "0"
                  })`,
                }}
              />
              : null
            }
            onClick={handleClickContext}
          >
            <Title level={3}>
              {isModeration ? 'Режим модерации' : 'Афиша'}
            </Title>
          </PanelHeaderContent>
        </PanelHeader>
        {isOdr?<br/>:null}
        <PanelHeaderContext
          opened={contextOpened}
          onClose={handleClickContext}
        >
          <List>
            <Cell
              before={<Icon24Similar/>}
              after={
                !isModeration ? (
                  <Icon24Done fill="var(--accent)"/>
                ) : null
              }
              onClick={offModerationMode}
            >
              Общая афиша
            </Cell>
            <Cell
              before={<Icon28ChecksOutline width={24} height={24}/>}
              after={
                isModeration ? (
                  <Icon24Done fill="var(--accent)"/>
                ) : null
              }
              onClick={onModerationMode}
            >
              Режим модерации
            </Cell>
          </List>
        </PanelHeaderContext>
        {
          !isModeration ?
            <Div>
              <Button
                before={<Icon28AddOutline/>}
                size='l'
                mode='outline'
                stretched
                onClick={handleClickNew}
              >
                Добавить мероприятие
              </Button>
            </Div>
            : null
        }

        <CardGrid size={isDesktop ? 'm' : 'l'} style={{paddingBottom: 12}}>
          {events?.length === 0 && !isModeration ?
            <Card style={{width: '100%', padding: 24}}>
              <Title level={3}>По выбранным фильтрам нет актуальных мероприятий.</Title>
              <br/>
              <Button onClick={clearFilters} mode='outline' appearance='overlay'>Очистить фильтры</Button>
            </Card> :
            null
          }
          {
            !events ?
              error ? <div>События не найдены</div> : <PanelSpinner/> :
              events.map((event) => (
                <Event
                  key={event.id}
                  onClick={() => handleClickEvent(event)}
                  {...event}
                  afterStyle={isModeration ? {marginBottom: 108, position: 'relative'} : undefined}
                  after={isAdmin && !isModeration || isModeration ? (
                    <ButtonGroup stretched mode='vertical' style={{padding: '12px 0'}}>
                      <ButtonGroup stretched>
                        <Button mode='outline' size='m' stretched={!isModeration} before={<Icon24PenOutline/>}
                                onClick={() => handleClickEdit(event)}>{isModeration ? null : 'Редактировать'}</Button>
                        <Button mode='outline' size='m' stretched={!isModeration} before={<Icon24DeleteOutline/>}
                                onClick={() => handleClickDelete(event)}>{isModeration ? null : 'Удалить'}</Button>
                        {
                          isModeration ?
                            <Button mode='outline' size='m' stretched
                                    onClick={() => handleClickModerate(event)}
                                    before={<Icon28ShareExternal width={24}/>}>Опубликовать</Button>
                            : null
                        }
                      </ButtonGroup>
                      {
                        isModeration ?
                          <Link target='_blank' rel='noreferrer' href={`https://vk.com/id${event.creatorId}`} style={{ width: '100%' }}>
                          <Button mode='outline' appearance='neutral' size='s' stretched
                                  before={<Icon24User/>}>Связаться с автором</Button>
                          </Link>
                          : null
                      }
                    </ButtonGroup>
                  ) : null}
                />
              ))
          }
        </CardGrid>
      </Panel>

      <EventPanel id='event' onClickBack={handleClickBack} event={activeEvent}/>
    </View>
  );
};

export default Poster;
