import { useRouter as useNextRouter } from 'next/router';
import {Tabbar as VKTabbar, TabbarItem} from "@vkontakte/vkui";
import {
  Icon28TearOffFlyerOutline,
  Icon28LogoClipsOutline,
  Icon28PlaylistOutline,
  Icon28LogoVkVideoOutline
} from "@vkontakte/icons";

import IconJazz from './jazz_icon.svg';

import {useGlobal} from "../../store/globalContext";
import {useRouter} from "../../store/routerContext";

const tabs = [
  {
    id: 'jazz',
    text: 'Jazz',
    icon: <IconJazz style={{ color: '--current-color' }} />,
  },
  {
    id: 'poster',
    text: 'Афиша',
    icon: <Icon28TearOffFlyerOutline/>,
  },
  {
    id: 'clips',
    text: 'Клипы',
    icon: <Icon28LogoClipsOutline/>,
  },
  {
    id: 'playlists',
    text: 'Плейлисты',
    icon: <Icon28PlaylistOutline/>,
  },
  {
    id: 'videos',
    text: 'Видео',
    icon: <Icon28LogoVkVideoOutline/>,
  }
]

const Tabbar = () => {
  const router = useNextRouter()
  const { isDesktop } = useGlobal()
  const {activePanel, setActivePanel} = useRouter()

  const onStoryChange = (e) => {
    setActivePanel(e.currentTarget.dataset.story);
    // const query = Object.keys(router?.query || {})
    //   .map(key => `${key}=${router?.query[key]}`)
    //   .join('&');
    router.push(`#${e.currentTarget.dataset.story}`)
  }

  return (
    <VKTabbar itemsLayout={isDesktop ? 'horizontal' : 'vertical'}>
      {
        tabs.map(tab => (
          <TabbarItem
            key={tab.id}
            onClick={onStoryChange}
            selected={activePanel === tab.id}
            data-story={tab.id}
            text={tab.text}
          >
            {tab.icon}
          </TabbarItem>
        ))
      }
      {/*<div style={{ width: '90%', border: '1px solid red', position: 'absolute', bottom: 100, left: 0, lineBreak: 'anywhere' }}>*/}
      {/*  {typeof window !== 'undefined' && JSON.stringify(window.location.href)}*/}
      {/*</div>*/}
    </VKTabbar>
  );
};


export default Tabbar;
