import {useEffect, useState} from 'react';
import {useRouter as useNextRouter} from 'next/router';
import Image from 'next/image';
import {View, Panel, PanelHeader, PanelHeaderContent, Div, Title} from '@vkontakte/vkui';

import { useGlobal } from '../../store/globalContext';
import { useRouter } from '../../store/routerContext';

import EventPanel from '../Event';

import JazzMap from '../../blocks/JazzMap';
import UpcomingEvent from '../../blocks/UpcomingEvent';
import LastVideo from '../../blocks/LastVideo';
import JazzArticle from '../../blocks/JazzArticle';

import AspectRatioBox from '../../components/AspectRatioBox';

import styles from './styles.module.scss';

const PANELS = [
  'jazz',
  'poster',
  'clips',
  'playlists',
  'videos',
]


const Jazz = ({article, settings}) => {
  const router = useNextRouter()
  const {setActivePanel: setRootActivePanel} = useRouter()

  const { isDesktop, isOdr } = useGlobal()
  const [activePanel, setActivePanel] = useState('jazz')
  const [activeEvent, setActiveEvent] = useState()

  const handleClickEvent = (event) => {
    router.push(`#poster|${event?.id}`)
    setActiveEvent(event)
    setActivePanel('event')
  }
  const handleClickBack = () => {
    setActivePanel('jazz')
  }

  useEffect(() => {
    router.beforePopState(({ as }) => {
      setActivePanel('jazz')
      if (as !== router.asPath) {
        const prevPanel = as.split('#')[1]
        if (PANELS.indexOf(prevPanel) > -1) {
          setRootActivePanel(prevPanel)
        }
        if (prevPanel.indexOf('poster|')>-1) {
          setActivePanel('poster')
        }
      }
      return true
    })
  }, [])

  return (
    <View activePanel={activePanel}>
      <Panel id='jazz'>
        {isOdr?<br/>:null}
        <PanelHeader fixed={false} separator={false} transparent>
          <PanelHeaderContent>
            <Title level={3} style={{color: 'white'}}>Джаз в России</Title>
          </PanelHeaderContent>
        </PanelHeader>
        {isOdr?<br/>:null}
        <div className={styles.cover}>
          <AspectRatioBox aspectRatio={isDesktop ? '3.3/1' : '1.64/1'}>
          <Image
            alt='100 лет Российскому джазу'
            src={`${isOdr?'.':''}/100yearscover${isDesktop?'_desktop':''}.png`}
            // width={isDesktop ? 518 : 375}
            // height={isDesktop ? 269 : 228}
            layout='fill'
            objectFit='cover'
            quality={100}
          />
          </AspectRatioBox>
        </div>

        <Div className={styles.content}>
          {
            settings?.isMapShown ?
              <>
                <JazzMap/>
                <br/>
              </>
              : null
          }
          <UpcomingEvent onClick={handleClickEvent}/>
          <br/>
          <LastVideo />

          {
            article ?
              <JazzArticle article={article} />
              : null
          }
        </Div>
      </Panel>

      <EventPanel id='event' event={activeEvent} onClickBack={handleClickBack}/>
    </View>
  );
};

export default Jazz;
