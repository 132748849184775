import { ButtonGroup, Button } from '@vkontakte/vkui';
import { Icon24PenOutline, Icon24DeleteOutline } from '@vkontakte/icons';

const EditButtonsGroup = ({ onClickEdit, onClickDelete }) => {
  return (
    <ButtonGroup stretched style={{ padding: '12px 0' }}>
      <Button mode='outline' size='m' stretched before={<Icon24PenOutline/>} onClick={onClickEdit}>Редактировать</Button>
      <Button mode='outline' size='m' stretched before={<Icon24DeleteOutline/>} onClick={onClickDelete}>Удалить</Button>
    </ButtonGroup>
  );
};

export default EditButtonsGroup;
