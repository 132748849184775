import {useState, useEffect, useCallback} from 'react';
import {useSWRConfig} from 'swr';

import {
  Div,
  Spinner,
  ButtonGroup,
  Button,
  ModalPage,
  ModalPageHeader,
  Separator,
  Alert,
  Title,
  FormLayout,
  FormItem,
  Input,
} from '@vkontakte/vkui';
import {Icon24Dismiss} from "@vkontakte/icons";

import axios from '../../api/axios';

// import {useUser} from '../../store/userContext';
import {useModal} from '../../store/modalContext';
import {useMedia, emptyMedia, MEDIA_TYPE} from "../../store/mediaContext";

import {modals} from '../../constants';

import styles from './styles.module.scss';

const normalizeValue = (key, value, type) => {
  switch (`${type}_${key}`) {
    case `${MEDIA_TYPE.clip}_link`:
    case `${MEDIA_TYPE.video}_link`:
      return value.match(/src="(.+?)"/gi)?.[0]?.split('"')?.[1] || value
    case `${MEDIA_TYPE.playlist}_link`:
      return value.match(/VK.Widgets.Playlist\((.+?)\);/gi)?.[0]?.split("(")?.[1]?.replace(/["');\s]/g, '') || value
    default:
      return value
  }
}

const normalizeData = ({/*creatorId, moderatorId, */...data}) => ({
  // creatorId,
  // moderatorId: creatorId,
  ...data
})

const linkRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gi

const validate = (eventData) => {
  const {
    type,
    link
  } = eventData
  const errors = {}

  Object.keys(eventData).forEach(k => {
    if (['title'].indexOf(k) === -1 && typeof eventData[k] == 'string' && eventData[k].length === 0) errors[k] = true
  })

  if (
    typeof link == 'string' && type !== MEDIA_TYPE.playlist && link.match(linkRegex) == null
    || !Boolean(link)
    || type === MEDIA_TYPE.clip && link.indexOf('https://vk.com/video_ext.php') === -1
    || type === MEDIA_TYPE.playlist && link.indexOf('vk.com/music') > -1
  ) {
    errors.link = true
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  }
}

const EditMedia = () => {
  const {mutate} = useSWRConfig()

  // const [user] = useUser()
  const {closeModal, setPopout, closePopout} = useModal()
  const {media: data, setMedia: setData} = useMedia()
  const [errors, setErrors] = useState({})

  const handleChange = useCallback((key, value) => {
    setData(prev => ({...prev, [key]: normalizeValue(key, value, data?.type)}))
    if (errors[key]) setErrors(prev => ({...prev, [key]: false}))
  }, [data])

  const handleChangeInput = (e) => handleChange(e.target.name, e.target.value)

  const handleClickSave = () => {
    const {id, ...normalizedData} = normalizeData(data)

    const {isValid, errors} = validate(normalizedData)

    if (!isValid) {
      // const input = document?.querySelector(`#${Object.keys(errors)[0]}_input`)
      // input?.scrollIntoView({ behavior: 'smooth' })
      setErrors(errors)
      return
    }

    if (data.id > 0) {
      axios.put(`/api/media/${id}`, normalizedData)
        .then(closeModal)
    } else {
      axios.post('/api/media', normalizedData)
        .then(closeModal)
    }
  }

  const handleClickCancel = () => {
    const isEditing = data?.id > 0
    setPopout(
      <Alert
        actions={[
          {
            title: "Вернуться",
            autoclose: true,
            mode: "default",
          },
          {
            title: "Ок",
            autoclose: true,
            mode: "destructive",
            action: () => {
              closeModal()
            }
          }
        ]}
        actionsLayout="horizontal"
        onClose={closePopout}
        header={isEditing ? 'Внимание' : 'Подтверждение'}
        text={isEditing ? 'Все несохраненные данные будут удалены' : 'Вы действительно хотите удалить введенные данные?'}
      />
    )
  }

  useEffect(() => {
    // if (data?.id === -1) setData(prev => ({...prev, creatorId: user.id}))

    return () => {
      if (data?.type) mutate(`/api/getMedia?type=${data.type}`)
      setData(emptyMedia)
    }
  }, [])

  return !data ? <Spinner/> : (
    <ModalPage
      id={modals.mediaModal}
      className={styles.modalPage}
      header={
        <>
          <ModalPageHeader
            after={
              <Button mode='tertiary' appearance='overlay' onClick={handleClickCancel}>
                <Icon24Dismiss/>
              </Button>
            }
          >
            <Title level={3}>{data?.id > 0 ? 'Редактирование' : 'Новый'}</Title>
          </ModalPageHeader>
          <Separator style={{color: 'var(--grey)'}}/>
        </>
      }
    >
      <FormLayout>
        <FormItem id='link_input' top='Код для вставки' status={errors.link ? "error" : ""} bottom={errors.link ? 'Неверный код' : <span>&nbsp;</span>}>
          <Input
            name='link'
            placeholder='Укажите код для вставки'
            value={data.link}
            onChange={handleChangeInput}
            maxLength="512"
          />
        </FormItem>

        {
          data?.type === MEDIA_TYPE.video ?
            <FormItem id='title_input' top='Заголовок'>
              <Input
                name='title'
                placeholder='Заголовок видео'
                value={data.title}
                onChange={handleChangeInput}
                maxLength="256"
              />
            </FormItem>
            : null
        }
      </FormLayout>
      <Div>
        <ButtonGroup stretched>
          <Button size='l' stretched onClick={handleClickSave}>Сохранить</Button>
          <Button mode='outline' size='l' stretched onClick={handleClickCancel}>Отменить</Button>
        </ButtonGroup>
      </Div>
    </ModalPage>
  );
};

export default EditMedia;
