import { createContext, useContext, useState } from 'react';

const RouterContext = createContext()

function RouterProvider({children}) {
  const [activePanel, setActivePanel] = useState('jazz')
  const [mutate, setMutateStr] = useState()

  const value = {activePanel, setActivePanel, mutate, setMutateStr}
  return <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
}

function useRouter() {
  const context = useContext(RouterContext)
  if (context === undefined) {
    throw new Error('useRouter must be used within a RouterProvider')
  }
  return context
}

export {RouterProvider, useRouter}
