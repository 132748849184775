import {useState} from "react";
import {File, Spinner} from "@vkontakte/vkui";
import {Icon24CameraOutline} from "@vkontakte/icons";

const ImageInput = ({onChange, defaultImg, ...props}) => {
  const [isLoading, setIsLoading] = useState()
  const [img, setImg] = useState(defaultImg)

  const handleChangeInput = (e) => {
    const files = e.target.files
    if (files && files.length) {
      const [file] = files
      setIsLoading(true)

      if (FileReader) {
        const file_reader = new FileReader();
        file_reader.onload = function () {
          const base64 = file_reader.result
          const name = file.name.replace(/\s/g, '')

          setImg(base64)
          setIsLoading(false)

          onChange({
            name,
            base64
          })
        }

        file_reader.readAsDataURL(file);
      } else {
        setIsLoading(false)
      }
    }
  }

  return (
    <File
      name='cover'
      mode='secondary'
      appearance='overlay'
      accept="image/png, image/jpeg"
      onChange={handleChangeInput}
      {...props}
    >
      {
        isLoading ? <Spinner/> :
          img ?
            // eslint-disable-next-line @next/next/no-img-element
            <img src={img} alt='Загружаемая обложка'/> :
            <Icon24CameraOutline/>
      }
    </File>
  );
};

export default ImageInput;
