import {useEffect, useState} from "react"

import { parseLaunchParams } from '../helpers/parseLaunchParams'

import {GlobalProvider} from './globalContext'
import {UserProvider} from './userContext'
import {RouterProvider} from './routerContext'
import {ModalProvider} from './modalContext'
import {EventProvider} from './eventContext'
import {MediaProvider} from './mediaContext'

const withStore = (Wrapped) => {
  const WithStore = (props) => {
    const [platform, setPlatform] = useState()
    const [userId, setUserId] = useState()

    const isDesktop = platform === 'desktop_web'

    useEffect(() => {
      const {vk_platform, vk_user_id} = parseLaunchParams(window.location.search.slice(1))
      setPlatform(vk_platform)
      setUserId(Number(vk_user_id))
    }, [])

    return userId ? (
      <GlobalProvider isDesktop={isDesktop}>
        <UserProvider userId={userId}>
          <RouterProvider>
            <ModalProvider>
              <EventProvider>
                <MediaProvider>

                  <Wrapped {...props} isDesktop={isDesktop} platform={platform} />

                </MediaProvider>
              </EventProvider>
            </ModalProvider>
          </RouterProvider>
        </UserProvider>
      </GlobalProvider>
    ) : null
  }

  WithStore.displayName = 'WithStore'
  return WithStore
}

export default withStore;
