import {useState, useEffect, useCallback} from "react";
import bridge from '@vkontakte/vk-bridge';
import format from 'date-fns/format';
import _isSameDay from "date-fns/isSameDay";
import _isSameMonth  from 'date-fns/isSameMonth';
import { ru } from 'date-fns/locale';
import {Panel, PanelHeader, PanelHeaderBack, Div, Title, Text, Link, Button } from '@vkontakte/vkui';

import { useGlobal } from '../../store/globalContext';

import EventCard from '../../components/EventCard';
import AspectRatioBox from '../../components/AspectRatioBox';

import styles from './styles.module.scss';
import axios from "../../api/axios";
import {useUser} from "../../store/userContext";

const _format = (d,f,c,...args) => d ? format(d,f,{...c, locale: ru}, ...args) : ''

const Event = ({onClickBack, event}) => {
  const [user] = useUser()
  const { isDesktop, isOdr } = useGlobal()

  const [subscription, setSubscription] = useState(event?.subscription)

  const {cover, title, startDate, endDate, country, city, address, buyLink, description} = event || {}

  const isSameDay = startDate && endDate && _isSameDay(startDate, endDate)
  const isSameMonth = _isSameMonth(startDate, endDate)
  const formattedDate = isSameMonth ?
      isSameDay ? _format(startDate, 'dd MMMM yyyy г.')
      : `${_format(startDate, 'dd')}–${_format(endDate, 'dd')} ${_format(startDate, 'MMMM yyyy')} г.`
    :
    `${_format(startDate, 'dd MMMM')} ${endDate > 0 ? ` – ${_format(endDate, 'dd MMMM yyyy г.')}` : _format(startDate, 'yyyy г.')}`
  const formattedAddress = `${country ? country?.split('|')[1]+',' : ''} ` + (city ? `г. ${city?.split('|')[1]}${address ? ', ' + address : ''}` : address)

  const subscribePush = useCallback(() => {
    if (!subscription) {
      bridge.send("VKWebAppAllowNotifications")
        .then(res => {
          if (res.result) {
            setSubscription(true)
            axios.put(`/api/subscribe`, {
              eventId: event.id,
              userId: user.id
            })
              .catch(() => setSubscription(false))
          }
        })
        .catch(() => {
          setSubscription(false)
          // code == 4 = User denied
          // console.log(err)
        })
    } else {
      setSubscription(false)
      axios.post(`/api/unsubscribe`, {
        eventId: event.id,
        userId: user.id
      })
        .catch(() => { setSubscription(false) })
    }
  }, [subscription])

  useEffect(() => {
    if (!event) onClickBack()
  }, [])

  return !event ? null : (
    <Panel>
      {isOdr?<br/>:null}
      <PanelHeader visor={false} separator={false} transparent before={<PanelHeaderBack onClick={onClickBack}/>}/>
      {isOdr?<br/>:null}
      <div className={styles.cover}>
        <AspectRatioBox aspectRatio={isDesktop ? '3/1' : undefined}>
          <img src={cover} alt={title}/>
        </AspectRatioBox>
      </div>
      <Div className={styles.content}>
        <Title level={3} className={styles.title}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Title>
        <EventCard date={formattedDate} address={formattedAddress}/>
        <Text weight='regular' style={{color: 'var(--grey)'}} className={styles.link}>
          Сайт мероприятия: <Link href={buyLink} target='_blank' rel='noreferrer'>{buyLink}</Link>
        </Text>
        {
          startDate && new Date(startDate) > new Date().setHours(0,0,0,0)
            ? (
              <Button stretched mode='outline' size='l' className={styles.notificationButton} onClick={subscribePush}>
                {subscription ? 'Отписаться' : 'Подписаться'}
              </Button>
            )
            : null
        }
        <Text weight='regular' style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{__html: description}}/>
      </Div>
    </Panel>
  );
};

export default Event;
