import axios from 'axios';

const isProduction = process.env.NODE_ENV === 'production';
const isOdr = typeof window != 'undefined' ? new URLSearchParams(window.location.search).get('odr_enabled') === '1' : false

export const baseURL = isProduction ? `${isOdr?'vkcors':'https'}://jazz.sorted.studio` : undefined
const instance = axios.create({
  // baseURL: 'https://jazz.sorted.studio'
  baseURL: isProduction ? `${isOdr?'vkcors':'https'}://jazz.sorted.studio` : undefined,
});

instance.defaults.headers.common['Authorization'] = typeof window != 'undefined' ? `Bearer ${window.location.search.slice(1)}` : '';
instance.defaults.mode = 'cors';

export const fetcher = url => instance.get(url).then(res => res.data)

export default instance;
