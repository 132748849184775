import {useState} from "react";
import {useRouter as useNextRouter} from 'next/router';
import {
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Div,
  FormItem,
  Textarea,
  Input,
  ButtonGroup,
  Button,
  Spinner
} from '@vkontakte/vkui';
import {Icon20ListAddOutline, Icon20ListPenOutline} from '@vkontakte/icons';

import axios from '../../api/axios';
import {useUser} from "../../store/userContext";
// import {useRouter} from "../store/routerContext";

import styles from './styles.module.scss';
import {useGlobal} from "../../store/globalContext";

const Article = ({id, article}) => {
  const router = useNextRouter()

  const [{isAdmin}] = useUser()
  const {isOdr} = useGlobal()
  // const {setActivePanel} = useRouter()

  const [isEdititng, setIsEditing] = useState(isAdmin)
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState(article?.title)
  const [text, setText] = useState(article?.text)

  const handleClickSave = () => {
    setIsLoading(true)
    axios.put(`/api/article`, {text, title})
      .then(({data}) => data)
      .then(json => console.log(json))
      .finally(() => setIsLoading(false))
  }

  return (
    <Panel id={id}>
      {isOdr?<br/>:null}
      <PanelHeader
        before={<PanelHeaderBack onClick={() => {
          router.back();/* setActivePanel('jazz');*/
        }}/>}
      >
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div>
            {title}
          </div>
          {/*{*/}
          {/*  isAdmin ?*/}
          {/*    <>*/}
          {/*      <div style={{paddingLeft: 16}}>*/}
          {/*        <Button mode='outeline' before={isEdititng ? <Icon20ListAddOutline /> : <Icon20ListPenOutline />} onClick={() => setIsEditing(e => !e)} />*/}
          {/*      </div>*/}
          {/*      <div style={{paddingLeft: 16}}>*/}
          {/*        {isLoading ? <Spinner/> : null}*/}
          {/*      </div>*/}
          {/*    </>*/}
          {/*    : null*/}
          {/*}*/}
        </div>
      </PanelHeader>
      {isOdr?<br/>:null}
      <Div className={styles.wrapper}>
        {
          isAdmin ?
            <>
              <ButtonGroup stretched>
                {/*<div style={{paddingLeft: 16}}>*/}
                <Button size='l' mode='outline' before={isEdititng ? <Icon20ListAddOutline/> : <Icon20ListPenOutline/>}
                        onClick={() => setIsEditing(e => !e)}/>
                {/*</div>*/}

                {isEdititng ?
                  <Button stretched size='l' mode='outline' onClick={handleClickSave}>Сохранить</Button>
                  : null}
              </ButtonGroup>

              <div style={{paddingLeft: 16}}>
                {isLoading ? <Spinner/> : null}
              </div>

              <br/>
            </>
            : null
        }

        {
          isEdititng ?
            <>
              <FormItem top='Заголовок'>
                <Input value={title} onChange={(e) => {
                  setTitle(e.target.value)
                }}/>
              </FormItem>
              <FormItem top='Статья'>
                <Textarea value={text} onChange={(e) => {
                  setText(e.target.value)
                }}/>
              </FormItem>
            </>
            :
            <div style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: text}}/>
        }
      </Div>
    </Panel>
  );
};

export default Article;
