import useSwr from 'swr';
import {Panel, PanelHeader, Div, Title, Button, Alert} from '@vkontakte/vkui';
import {Icon28AddOutline} from "@vkontakte/icons";

import {modals} from "../constants";
import axios from "../api/axios";

import {useGlobal} from '../store/globalContext';
import {useModal} from '../store/modalContext';
import {useUser} from "../store/userContext";
import {useMedia, MEDIA_TYPE} from "../store/mediaContext";

import AspectRatioBox from '../components/AspectRatioBox';
import Iframe from '../components/Iframe';
import EditButtonsGroup from "../components/EditButtonsGroup";

const Clips = () => {
  const {isDesktop, isOdr} = useGlobal()
  const {setModal, setPopout, closePopout} = useModal()
  const {setMedia, setMediaType} = useMedia()
  const [{isAdmin}] = useUser()

  const {data, mutate} = useSwr('/api/getMedia?type=Clip')

  const handleClickNew = () => {
    setMediaType(MEDIA_TYPE.clip)
    setModal(modals.mediaModal)
  }

  const handleClickEdit = (media) => {
    setMedia(media)
    setModal(modals.mediaModal)
  }

  const handleClickDelete = (clip) => {
    setPopout(
      <Alert
        actions={[
          {
            title: "Оставить",
            mode: "default",
            action: closePopout
          },
          {
            title: "Ок",
            autoclose: true,
            mode: "destructive",
            action: () => {
              axios.delete(`/api/media/${clip?.id}`).finally(() => mutate())
            }
          }
        ]}
        actionsLayout="horizontal"
        onClose={closePopout}
        header='Подтверждение'
        text='Вы действительно хотите удалить клип?'
      />
    )
  }

  return (
    <Panel>
      {isOdr?<br/>:null}
      <PanelHeader fixed={false} separator={false} transparent>
        <Title level={3}>Клипы</Title>
      </PanelHeader>
      {isOdr?<br/>:null}

      {
        isAdmin ?
          <Div>
            <Button
              before={<Icon28AddOutline/>}
              size='l'
              mode='primary'
              stretched
              onClick={handleClickNew}
            >
              Добавить клип
            </Button>
          </Div>
          : null
      }

      <Div>
        {!data ? null : data.map((clip, idx) => {
          const urlParams = new URLSearchParams(clip?.link?.split('?')[1])
          const oid = urlParams.get('oid')
          const id = urlParams.get('id')
          return (
            <div key={idx} style={{marginBottom: '12px', paddingBottom: 12}}>
              {isDesktop ?
                <div style={{width: 325, height: 576, margin: '0 auto'}}>
                  <Iframe
                    src={clip?.link}
                    width="100%"
                    height='100%'
                    allow="autoplay; encrypted-media;"
                    frameBorder="0"
                    allowFullScreen
                    loading='lazy'
                  />
                </div>
                :
                <a href={`https://vk.com/clip${oid}_${id}`} target='_blank' rel='noreferrer' style={{ display: 'block', margin: 'auto', width: '75vw' }}>
                  <AspectRatioBox aspectRatio={'9/16'}>
                    <Iframe
                      style={{ pointerEvents: 'none' }}
                      src={clip?.link}
                      width="100%"
                      height='100%'
                      allow="autoplay; encrypted-media;"
                      frameBorder="0"
                      loading='lazy'
                    />
                  </AspectRatioBox>
                </a>
              }
              {
                !isDesktop ?
                  <p style={{opacity: .75}}>{clip?.title}</p>
                  : null
              }
              {
                isAdmin ?
                  <EditButtonsGroup onClickEdit={() => handleClickEdit(clip)}
                                    onClickDelete={() => handleClickDelete(clip)}/>
                  : null
              }
            </div>
          )
        })}
      </Div>
    </Panel>
  );
};

Clips.propTypes = {};

export default Clips;
