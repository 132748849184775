import { createContext, useContext, useState } from 'react';

const MediaContext = createContext()

export const MEDIA_TYPE = {
  clip: 'Clip',
  video: 'Video',
  playlist: 'Playlist',
}

export const emptyMedia = {
  id: -1,
  type: '',
  link: '',
  title: '',
  onModaration: false,
  isPublished: false,
  // creatorId: 0,
  // moderatorId: 0
}

function MediaProvider({children}) {
  const [media, setMedia] = useState(emptyMedia)

  const setMediaType = (type) => {
    if (typeof type == 'string' && MEDIA_TYPE[type.toLocaleLowerCase()]) setMedia(prev => ({ ...prev, type }))
  }

  const value = {media, setMedia, setMediaType}
  return <MediaContext.Provider value={value}>{children}</MediaContext.Provider>
}

function useMedia() {
  const context = useContext(MediaContext)
  if (context === undefined) {
    throw new Error('useMedia must be used within a MediaProvider')
  }
  return context
}

export {MediaProvider, useMedia}
