import {
  Div,
  ButtonGroup,
  Button,
  ModalPage,
  ModalPageHeader,
  Separator,
  Title,
  FormLayout,
  FormItem,
  DatePicker,
  Checkbox,
} from '@vkontakte/vkui';
import { Icon24Dismiss } from '@vkontakte/icons';

import { useModal } from '../../store/modalContext';

import { modals } from '../../constants';

import CountrySelect from '../../components/CountrySelect';
import CitySelect from '../../components/CitySelect';
import { useEventsFilter } from '../../store/eventsFilterContext';

const EditEvent = () => {
  const { closeModal } = useModal();

  const [data, setData, clearFilters] = useEventsFilter();

  const handleChange = (key, value) =>
    setData((prev) => ({ ...prev, [key]: value }));

  const handleChangeDateFrom = (value) => {
    handleChange('from', +new Date(value.year, value.month - 1, value.day));
  };
  const handleChangeDateTo = (value) => {
    handleChange('to', +new Date(value.year, value.month - 1, value.day));
  };
  const handleChangeCountry = (country) => {
    handleChange('country', country);
  };
  const handleChangeCity = (city) => {
    handleChange('city', city);
  };
  const handleClickClear = () => {
    clearFilters();
    closeModal();
  };

  const handleClickCancel = () => {
    closeModal();
  };

  const dateFrom = data.from > 0 ? new Date(data.from) : new Date();
  const dateTo = data.to ? new Date(data.to) : undefined; //add(new Date(), { weeks: 1 })

  return (
    <ModalPage
      id={modals.eventsFilter}
      header={
        <>
          <ModalPageHeader
            after={
              <Button
                mode="tertiary"
                appearance="overlay"
                onClick={handleClickCancel}
              >
                <Icon24Dismiss />
              </Button>
            }
          >
            <Title level={3}>Фильтры</Title>
          </ModalPageHeader>
          <Separator style={{ color: 'var(--grey)' }} />
        </>
      }
    >
      <FormLayout>
        <FormItem top="Дата от">
          <DatePicker
            disabled={Boolean(data.subscriptions)}
            min={{ day: 1, month: 1, year: 2022 }}
            max={{ day: 1, month: 1, year: 2222 }}
            defaultValue={{
              day: dateFrom.getDate(),
              month: dateFrom.getMonth() + 1,
              year: dateFrom.getFullYear(),
            }}
            popupDirection="top"
            onDateChange={handleChangeDateFrom}
            dayPlaceholder="ДД"
            monthPlaceholder="ММММ"
            yearPlaceholder="ГГГГ"
          />
        </FormItem>
        <FormItem top="Дата до">
          <DatePicker
            disabled={Boolean(data.subscriptions)}
            min={{
              day: dateFrom.getDate(),
              month: dateFrom.getMonth() + 1,
              year: dateFrom.getFullYear(),
            }}
            max={{ day: 1, month: 1, year: 2222 }}
            defaultValue={
              dateTo
                ? {
                    day: dateTo.getDate(),
                    month: dateTo.getMonth() + 1,
                    year: dateTo.getFullYear(),
                  }
                : {
                    year: dateFrom.getFullYear(),
                  }
            }
            popupDirection="top"
            onDateChange={handleChangeDateTo}
            dayPlaceholder="ДД"
            monthPlaceholder="ММММ"
            yearPlaceholder="ГГГГ"
          />
        </FormItem>
        <FormItem top="Страна">
          <CountrySelect
            disabled={Boolean(data.subscriptions)}
            selected={data.country}
            onChange={handleChangeCountry}
          />
        </FormItem>
        <FormItem top="Город">
          <CitySelect
            disabled={Boolean(data.subscriptions)}
            selected={data.city}
            countryId={Number(data.country.split('|')[0])}
            onChange={handleChangeCity}
          />
        </FormItem>
        <Checkbox
          checked={Boolean(data.subscriptions)}
          onChange={(e) =>
            handleChange('subscriptions', e.target.checked ? 'true' : null)
          }
        >
          Мои подписки
        </Checkbox>
      </FormLayout>
      <Div>
        <ButtonGroup stretched>
          <Button mode="outline" size="l" stretched onClick={closeModal}>
            Применить
          </Button>
          <Button mode="outline" size="l" stretched onClick={handleClickClear}>
            Сбросить
          </Button>
        </ButtonGroup>
      </Div>
    </ModalPage>
  );
};

export default EditEvent;
