import useSWR from 'swr';
import {Panel, PanelHeader, Title, CardGrid, Card, PanelSpinner, Div, Button, Alert, Link} from "@vkontakte/vkui";
import { Icon28AddOutline, Icon28ArrowUpRightOutSquareOutline } from '@vkontakte/icons';

import {useGlobal} from '../../store/globalContext';
import {useUser} from "../../store/userContext";
import {useModal} from "../../store/modalContext";
import {MEDIA_TYPE, useMedia} from "../../store/mediaContext";
import {modals} from "../../constants";

import Iframe from '../../components/Iframe';
import EditButtonsGroup from '../../components/EditButtonsGroup';

import styles from './styles.module.scss';
import axios from "../../api/axios";

const Videos = () => {
  const {isDesktop, isOdr} = useGlobal()
  const [{isAdmin}] = useUser()
  const {setMedia, setMediaType} = useMedia()
  const {setModal, setPopout, closePopout} = useModal()

  const {data, error, mutate} = useSWR(`/api/getMedia?type=${MEDIA_TYPE.video}`)

  const handleClickNew = () => {
    setMediaType(MEDIA_TYPE.video)
    setModal(modals.mediaModal)
  }

  const handleClickEdit = (media) => {
    setMedia(media)
    setModal(modals.mediaModal)
  }

  const handleClickDelete = (video) => {
    setPopout(
      <Alert
        actions={[
          {
            title: "Оставить",
            mode: "default",
            action: closePopout
          },
          {
            title: "Ок",
            autoclose: true,
            mode: "destructive",
            action: () => {
              axios.delete(`/api/media/${video?.id}`).finally(() => mutate())
            }
          }
        ]}
        actionsLayout="horizontal"
        onClose={closePopout}
        header='Подтверждение'
        text='Вы действительно хотите удалить видео?'
      />
    )
  }

  return (
    <Panel>
      {isOdr?<br/>:null}
      <PanelHeader fixed={false} separator={false} transparent>
        <Title level={3}>Видео</Title>
      </PanelHeader>
      {isOdr?<br/>:null}

      {
        isAdmin ?
          <Div>
            <Button
              before={<Icon28AddOutline/>}
              size='l'
              mode='primary'
              stretched
              onClick={handleClickNew}
            >
              Добавить видео
            </Button>
          </Div>
          : null
      }

      {/*<Div>*/}
      <CardGrid size={isDesktop ? 'm' : 'l'}>
        {
          !data ? error || data?.length === 0 ? <div>Пока нет видео</div> : <PanelSpinner/> :
            data.map((video) => {
              const videoLinkParams = video?.link.match(/oid=(.+?)&id=(.+?)&/)
              return (
                <Card key={video?.id} className={styles.video}>
                  <Iframe
                    src={video?.link}
                    width="100%"
                    height="180"
                    allow="autoplay; encrypted-media;"
                    frameBorder="0"
                    allowFullScreen={isDesktop}
                    loading='lazy'
                    style={{borderRadius: 10}}
                  />
                  {
                    video?.title ?
                      <Title level={4} className={styles.videoTitle}>
                        <span>{video.title}</span>
                        {
                          videoLinkParams ?
                        <Link href={`https://vk.com/video${videoLinkParams[1]}_${videoLinkParams[2]}`} rel='noreferrer' target='_blank'><Icon28ArrowUpRightOutSquareOutline width={20} /></Link>
                            : null
                        }
                      </Title>
                      : null
                  }

                  {isAdmin ? <EditButtonsGroup onClickEdit={() => handleClickEdit(video)} onClickDelete={() => handleClickDelete(video)} /> : null}
                </Card>
              )
            })
        }
      </CardGrid>
      {/*</Div>*/}
    </Panel>
  );
};

export default Videos;
