import {useEffect, useState, useRef, forwardRef} from "react";
import {CustomSelect, IconButton} from "@vkontakte/vkui";
import {Icon24Cancel} from "@vkontakte/icons";
import bridge from "@vkontakte/vk-bridge";

let filterTimeout;
const CitySelect = ({countryId = 1, selected, onChange, clearable = false, ...props}, ref) => {
  const token = useRef('')
  const [isLoading, setIsLoading] = useState(false)
  const [citiesToSelect, setCitiesToSelect] = useState([])

  const handleSearch = (e) => {
    clearTimeout(filterTimeout)
    if (!e.target.value) return setCitiesToSelect([])

    filterTimeout = setTimeout(() => {
      setIsLoading(true)
      bridge.send(
        "VKWebAppCallAPIMethod",
        {
          method: "database.getCities",
          params: {
            v:"5.131",
            access_token: token.current,
            country_id: countryId,
            q: e.target.value,
          }
        })
        .then(({ response }) => {
          const cities = response.items.map(({ id, title }) => ({ value: `${id}|${title}`, label: title }))
          setIsLoading(false)
          setCitiesToSelect(cities)
        })
    }, 500)
  }

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  useEffect(() => {
    if (selected === '') {
      setCitiesToSelect([])
    }
  }, [selected])
  useEffect(() => {
    bridge.send("VKWebAppGetAuthToken", { app_id: 8182278, scope: "" })
      .then(({ access_token }) => {
        if (access_token) token.current = access_token

        handleSearch({ target: { value: selected.indexOf('|') > -1 ? selected.split('|')[1] : selected } })
      })
  }, [])

  return (
    <CustomSelect
      value={selected}
      ref={ref}
      searchable
      options={[{label: 'Все города', value: ''}, ...citiesToSelect]}
      fetching={isLoading}
      onInputChange={handleSearch}
      filterFn={false}
      popupDirection='top'
      onChange={handleChange}
      icon={
        Boolean(selected) ?
          <IconButton
            mode='tertiary'
            appearance='overlay'
            onClick={(e) => { e.stopPropagation(); onChange(''); }}
          >
            <Icon24Cancel />
          </IconButton>
          : undefined
      }
      {...props}
    />
  )
}

export default forwardRef(CitySelect)
