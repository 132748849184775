import { Title, Button } from '@vkontakte/vkui';

import styles from './styles.module.scss';

const BlockHeader = ({ title, buttonText = 'Показать все', onClick }) => {
  return (
    <div className={styles.wrapper}>
      <Title level={3} className={styles.header}>{title}</Title>
      <Button
        mode='tertiary'
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default BlockHeader;
