import {useRouter as useNextRouter} from 'next/router';
import useSWR from 'swr';
import format from 'date-fns/format';
import _isSameDay from 'date-fns/isSameDay';
import _isSameMonth from 'date-fns/isSameMonth';
import { ru } from 'date-fns/locale';
import { Spinner, Div, Card, Text } from '@vkontakte/vkui';

import BlockHeader from '../../components/BlockHeader';
import EventCard from '../../components/EventCard';

import {useRouter} from '../../store/routerContext';
import {useEventsFilter} from '../../store/eventsFilterContext';

const today = +new Date()

const _format = (d,f,c,...args) => d ? format(d,f,{...c,locale:ru}, ...args) : '' // TODO: move _format util globally

const UpcomingEvent = ({ onClick }) => {
  const router = useNextRouter()
  const {setActivePanel} = useRouter()
  const [filters] = useEventsFilter()

  const { data, error } = useSWR(`/api/getUpcomingEvent?city=${filters?.city || '1|Москва'}&date=${today}`)

  const isSameDay = data?.startDate && data?.endDate && _isSameDay(data?.startDate, data?.endDate)
  const isSameMonth = data?.startDate && data?.endDate && _isSameMonth(data?.startDate, data?.endDate)
  const formattedDate = isSameMonth ?
    isSameDay ? _format(data?.startDate, 'dd MMMM yyyy г.') :
    `${_format(data?.startDate, 'dd')}–${_format(data?.endDate, 'dd')} ${_format(data?.startDate, 'MMMM yyyy')} г.`
    : `${_format(data?.startDate, 'dd MMMM')} ${data?.endDate > 0 ? ` – ${_format(data?.endDate, 'dd MMMM yyyy г.')}` : _format(data?.startDate, 'yyyy г.')}`
  const formattedAddress = `${data?.country ? data?.country?.split('|')[1]+',' : ''} ` + (data?.city ? `г. ${data?.city?.split('|')[1]}${data?.address ? ', ' + data?.address : ''}` : data?.address)

  const handleClickAll = () => {
    router.push('#poster')
    setActivePanel('poster')
  }

  const handleClick = () => {
    onClick(data)
    // onClick(event)
  }

  // useEffect(() => { mutate() }, [filters])

  // console.log(data, error, `/api/getUpcomingEvent?city=${user.city}&date=${+new Date()}`)
  return (
    <div>
      <BlockHeader title='Ближайшее событие' onClick={handleClickAll}/>
      {error ? <Card><Div><Text weight='regular'>В выбранном городе нет актуальных мероприятий.</Text></Div></Card> : null}
      {!data ?
        error ? null :
        <Spinner/> :
        <EventCard address={formattedAddress} date={formattedDate} onClick={handleClick}/>
      }
    </div>
  );
};

export default UpcomingEvent;
