import { createContext, useContext, useState } from 'react';

const UserContext = createContext()

function UserProvider({children, userId}) {
  const [user, setUser] = useState({ id: userId, isAdmin: false, city: '1|Москва' })
  const value = [user, setUser]
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

function useUser() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

export {UserProvider, useUser}
