import {Card, Div} from "@vkontakte/vkui";
import {Icon20ChevronRightOutline} from "@vkontakte/icons";

import Address from "../Address";

import styles from "./styles.module.scss";

const EventCard = ({date, address, onClick}) =>
  <Card onClick={onClick} style={{cursor: onClick ? 'pointer' : ''}}>
    <Div className={styles.cardContent}>
      <div>
        <div className={styles.date}>{date}</div>
        <Address address={address}/>
      </div>
      {onClick ? <Icon20ChevronRightOutline/> : null}
    </Div>
  </Card>

export default EventCard;
