export const modals = {
  mainEvent: 'MAIN_EVENT_CITY_SELECT',
  newEvent: 'NEW_EVENT',
  mediaModal: 'MEDIA',
  eventsFilter: 'EVENTS_FILTER'
};

export const mainEventCities = [
  {
    id: 1,
    title: 'Москва',
  },
  {
    id: 2,
    title: 'Санкт-Петербург'
  },
  {
    id: 112,
    title: 'Петрозаводск'
  },
  {
    id: 169,
    title: 'Ярославль'
  },
  {
    id: 95,
    title: 'Нижний Новгород'
  },
  {
    id: 158,
    title: 'Челябинск'
  },
  {
    id: 123,
    title: 'Самара'
  },
  {
    id: 60,
    title: 'Казань'
  },
  {
    id: 49,
    title: 'Екатеринбург'
  },
  {
    id: 99,
    title: 'Новосибирск'
  },
  {
    id: 110,
    title: 'Пермь'
  },
  {
    id: 72,
    title: 'Краснодар'
  },
  {
    id: 133,
    title: 'Сочи'
  },
  {
    id: 119,
    title: 'Ростов-на-Дону'
  },
  {
    id: 185,
    title: 'Севастополь'
  },
  {
    id: 73,
    title: 'Красноярск'
  },
  {
    id: 61,
    title: 'Калининград'
  },
  {
    id: 167,
    title: 'Южно-Сахалинск'
  }
]
