import Image from 'next/image';
import { Button } from '@vkontakte/vkui';

import AspectRatioBox from '../../components/AspectRatioBox';
import Address from '../../components/Address';

import {useUser} from "../../store/userContext";
import {useModal} from "../../store/modalContext";

import { modals } from '../../constants';

import styles from './styles.module.scss';

const isOdr = typeof window != 'undefined' ? new URLSearchParams(window.location.search).get('odr_enabled') === '1' : false

const JazzMap = () => {
  const [user] = useUser();
  const {setModal} = useModal();

  const userCity = user?.city || '1|Москва'
  const city = userCity?.split('|')[1]

  const handleClickOther = () => {
    setModal(modals.mainEvent)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.citySelector}>
        <Address address={`г. ${city || 'Москва'}`} />
        <Button mode='tertiary' onClick={handleClickOther}>Где ещё</Button>
      </div>
      <div style={{ maxWidth: 518, maxHeight: 269, margin: 'auto' }}>
        <AspectRatioBox aspectRatio='1.9/1'>
          <Image src={`${isOdr?'.':''}/map.png`} layout='fill' objectFit='contain' quality={100} />
        </AspectRatioBox>
      </div>
      {/*<Map />*/}
    </div>
  );
};

export default JazzMap;
