import React, { createContext, useContext, useState } from 'react';
import {ScreenSpinner} from "@vkontakte/vkui";

const MODAL_CARD_QR_SUCCESS = 'qr_success_scan'

const defaultSpinner = <ScreenSpinner size='large' />

const ModalContext = createContext()

function ModalProvider({children}) {
  const [activeModal, setModal] = useState(null)
  const [popout, setPopout] = useState()
  const [snackbar, setSnackbar] = useState(null)

  const closeModal = () => {
    setModal(null)
  }
  const closePopout = () => {
    setPopout(null)
  }
  const closeSnackbar = () => {
    setSnackbar(null)
  }

  const spin = (spinner = defaultSpinner) => {
    setPopout(spinner)
  }

  const value = {
    activeModal,
    setModal,
    closeModal,
    popout,
    setPopout,
    closePopout,
    snackbar,
    setSnackbar,
    closeSnackbar,
    spin
  }

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

function useModal() {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}

export {ModalProvider, useModal, MODAL_CARD_QR_SUCCESS}
