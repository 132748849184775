// import {useEffect, useState} from 'react';
// import { withAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { useRouter as useNextRouter } from 'next/router';
import useSwr from 'swr';
import { useGlobal } from '../../store/globalContext';

import BlockHeader from "../../components/BlockHeader";
import AspectRatioBox from "../../components/AspectRatioBox";
import Iframe from "../../components/Iframe";

import {useRouter} from "../../store/routerContext";

// import styles from "../styles.module.scss";

const LastVideo = () => {
  const router = useNextRouter()
  const { isDesktop } = useGlobal()
  const {setActivePanel} = useRouter()

  const {data} = useSwr('/api/getLastVideo')

  const handleClickAll = () => {
    router.push('#videos')
    setActivePanel('videos')
  }

  if (!data) return <div>loading...</div>
  return (
    <div style={{ background: '#05044A' }}>
      <BlockHeader title='Последнее видео' onClick={handleClickAll} />
      <AspectRatioBox aspectRatio={isDesktop ? '2/1' : undefined}>
      <Iframe
        src={data?.link}
        width="100%"
        height="100%"
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
        frameBorder="0"
        allowFullScreen
        style={{ borderRadius: 10 }}
        loading='lazy'
      />
      </AspectRatioBox>
    </div>
  );
};

export default LastVideo;
