import {useEffect, useState} from 'react';
import {ModalPage, ModalPageHeader, Button, Separator, Cell, Footer, Search, Title} from "@vkontakte/vkui";
import { Icon24Dismiss, Icon24CheckCircleOn } from '@vkontakte/icons';

import {useUser} from '../store/userContext';
import {useEventsFilter} from '../store/eventsFilterContext';
import {useModal} from '../store/modalContext';

import {modals} from "../constants";

const MainEventCitySelect = ({ anniversaryCities }) => {
  const [user, setUser] = useUser()
  const {closeModal} = useModal()
  const [,setFilters] = useEventsFilter()

  const [search, setSearch] = useState('')
  const [cities, setCities] = useState(anniversaryCities)

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const handleCityClick = (city) => {
    setUser(prev => ({ ...prev, city }))
    setFilters(prev => ({ ...prev, city }))
    closeModal()
  }

  useEffect(() => {
    const filteredCities = anniversaryCities.filter(
      ({title}) => title.toLowerCase().indexOf(search.toLowerCase()) > -1
    )
    setCities(filteredCities);
  }, [search])

  return (
    <ModalPage
      id={modals.mainEvent}
      onClose={closeModal}
      header={
        <>
          <ModalPageHeader
            after={
                <Button mode='tertiary' appearance='overlay' onClick={closeModal}>
                  <Icon24Dismiss />
                </Button>
            }
          >
            <Title level={3}>Город</Title>
          </ModalPageHeader>
          <Separator style={{color: 'var(--grey)'}}/>
        </>
      }
      settlingHeight={100}
    >
      <Search
        value={search}
        onChange={handleSearch}
        after={null}
      />
      {cities.length > 0 &&
      cities.map((city) => {
        const value = `${city.id}|${city.title}`
        const isSelected = value === user.city
        return (
          <Cell key={city.id} after={isSelected ? <Icon24CheckCircleOn /> : null} onClick={() => handleCityClick(value)}>{city.title}</Cell>
        )
      })}
      {cities.length === 0 && <Footer>Ничего не найдено</Footer>}
    </ModalPage>
  );
};

export default MainEventCitySelect;
