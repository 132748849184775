import { createContext, useContext, useState } from 'react';

const GlobalContext = createContext()
const isOdr = typeof window != 'undefined' ? new URLSearchParams(window.location.search).get('odr_enabled') === '1' : false

function GlobalProvider({children, isDesktop = false}) {
  // const [user, setGlobal] = useState({ isDesktop })
  // const value = [user, setGlobal]
  const value = { isDesktop, isOdr }
  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

function useGlobal() {
  const context = useContext(GlobalContext)
  if (context === undefined) {
    throw new Error('useGlobal must be used within a GlobalProvider')
  }
  return context
}

export {GlobalProvider, useGlobal}
