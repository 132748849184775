import {useRef} from "react";
import format from 'date-fns/format';
import _isSameDay from "date-fns/isSameDay";
import _isSameMonth from 'date-fns/isSameMonth';
// import _isSameYear from 'date-fns/isSameYear';
import {ru} from 'date-fns/locale'
import {Card, Text} from '@vkontakte/vkui';
import {Icon24Fire} from '@vkontakte/icons';

import AspectRatioBox from '../../components/AspectRatioBox';
import Address from '../../components/Address';

import styles from './styles.module.scss';

const _format = (d, f, c, ...args) => d ? format(d, f, { ...c, locale: ru }, ...args) : ''
// const _formatRange = (start, end) => {
//   const isSameMonth = _isSameMonth(start, end)
//   const isSameYear = _isSameYear(start, end)
//
//   const startYear = _format(start, 'yyyy г.')
//   const startFormatted = _format(start, 'dd MMMM')
//
//   const endFormatted = _format(end, 'dd MMMM')
//   const endYear = _format(end, 'yyyy г.')
// }

const Event = ({id, title, cover, country, city, address, startDate, endDate, tags, onClick, after, afterStyle = {marginBottom: 60, position: 'relative'}}) => {
  const ref = useRef()

  const isSameDay = _isSameDay(startDate, endDate)
  const isSameMonth = _isSameMonth(startDate, endDate)
  const formattedDate = isSameMonth ?
    isSameDay ? _format(startDate, 'dd MMMM yyyy г.')
    : `${_format(startDate, 'dd')}–${_format(endDate, 'dd')} ${_format(startDate, 'MMMM yyyy')} г.`
    : `${_format(startDate, 'dd MMMM')} ${endDate > 0 ? ` – ${_format(endDate, 'dd MMMM yyyy г.')}` : _format(startDate, 'yyyy г.')}`

  const formattedAddress = `${country ? country?.split('|')[1]+',' : ''} ` + (city ? `г. ${city.split('|')[1]}${address ? ', ' + address : ''}` : address)

  return (
    <Card key={id} mode="shadow" onClick={onClick} style={after ? afterStyle : {}}
          getRootRef={ref}>
      <AspectRatioBox className={styles.content}>
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }}/>
        {cover ? <img src={cover} alt={title} className={styles.cover}/> : null}
        <div>
          <Text weight='regular'>{formattedDate}</Text>
          <Address address={formattedAddress} accent/>
        </div>
        {tags?.indexOf('SPECIAL') > -1 ? <Icon24Fire className={styles.important} width={16} /> : null}
      </AspectRatioBox>
      {
        after ?
          <div style={{position: 'absolute', top: '100%', width: '100%'}} onClick={e => e.stopPropagation()}>
            {after}
          </div>
          : null
      }
    </Card>
  );
};

export default Event;
